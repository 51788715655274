<template>
  <div id="covid">
    <Navbar class="dark-menu-icon" />

    <h1 class="page-title">
      Notice of COVID-19 Temporary Emergency Procedures
    </h1>

    <p>
      As the music community, the nation, and the world continue to grapple with
      the unprecedented disruption caused by COVID-19, HFA continues to work
      hard to deliver payments and statements to music publishers. Although HFA
      has closed its office due to the New York Governor’s stay-at-home order,
      we have been able to transition our services to enable our employees to
      work remotely in the safety of their homes.
    </p>

    <p>
      However, because we have had to move our resources online, certain
      publishers who have elected to receive NOIs by mail will be temporarily
      transitioned to electronic service of NOIs. Those publishers affected by
      the emergency measures have been notified and been given instructions on
      how to set up an online account with HFA. For all other publishers, you
      should not see any disruption in the delivery of your NOIs via HFA’s
      online portal.
    </p>

    <p>
      The Copyright Office has recognized that the COVID-19 national emergency
      has caused a general disruption in the ordinary functioning of the
      copyright system, and has authorized HFA to suspend service of paper NOIs
      during this disruption. For more information about the Copyright Office’s
      announcement, visit
      <a href="https://www.copyright.gov/coronavirus/" target="_blank"
        >https://www.copyright.gov/coronavirus/</a
      >.
    </p>

    <p>
      Once HFA resumes normal business operations, we will mail to those
      publishers paper copies of the NOIs that were served electronically,
      unless those publishers advise us that they wish to continue to receive
      NOIs electronically.
    </p>

    <p>
      At this time, we do not expect any disruption in payments, either by check
      or electronic transfer. However, unanticipated disruptions to mail or
      restrictions on office travel may delay delivery of paper checks.
    </p>

    <p>
      If you have any questions, you may contact us at
      <a href="mailto:clientservices@harryfox.com"
        >clientservices@harryfox.com</a
      >.
    </p>

    <p>
      We hope that everyone stays safe and healthy during this extraordinary
      time.
    </p>
  </div>
</template>

<script>
import Navbar from "@/components/Navbar.vue";

export default {
  name: "Covid",
  components: {
    Navbar
  },
  metaInfo: function() {
    return {
      title: " - COVID-19 Temporary Emergency Procedures",
      meta: [
        {
          name: "description",
          content: "COVID-19 Temporary Emergency Procedures"
        }
      ],
      link: [
        {
          vmid: "canonical",
          rel: "canonical",
          href: this.$store.getters["canonical"]()
        }
      ]
    };
  },
  created: function() {
    this.$emit("hide-banner");
  }
};
</script>

<style lang="scss">
#covid {
  h1 {
    margin-top: 40px;
  }

  p {
    font-size: 1.2em;

    @media (min-width: $desktop) {
      margin-right: 50px;
    }

    a {
      color: #0000ee;
    }
  }
}
</style>
